<template>
  <div>
    <DataTable :options="options" />
    <vx-dialog
      v-model="dialog"
      title="Select Role"
      width="400"
      actions
      @resolve="$add(options.route, { roleType: addOption })"
    >
      <vx-input
        v-model="addOption"
        type="autocomplete"
        item-text="name"
        item-value="_value"
        :items="addOptions"
        :clearable="false"
        name="addOptions"
        label="Select Role To Add"
      />
    </vx-dialog>
  </div>
</template>

<script>
import { ROLE_TYPE, ROLE_TYPE_MAPPER } from '@tutti/constants';
export default {
  data() {
    return {
      dialog: false,
      addOptions: [
        {
          name: 'User',
          _value: 'user',
        },
        {
          name: 'Profile',
          _value: 'account',
        },
      ],
      addOption: ROLE_TYPE.USER,
      options: {
        addNew: this.addNew,

        headers: [
          { text: 'Name', value: 'name' },
          {
            text: 'Type',
            value: 'type',
            getter: item => {
              return ROLE_TYPE_MAPPER[item.type];
            },
          },
        ],
        action: {
          hideView: true,
        },
      },
    };
  },
  methods: {
    addNew() {
      this.dialog = true;
    },
  },
};
</script>
