var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('DataTable', {
    attrs: {
      "options": _vm.options
    }
  }), _c('vx-dialog', {
    attrs: {
      "title": "Select Role",
      "width": "400",
      "actions": ""
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.$add(_vm.options.route, {
          roleType: _vm.addOption
        });
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "item-text": "name",
      "item-value": "_value",
      "items": _vm.addOptions,
      "clearable": false,
      "name": "addOptions",
      "label": "Select Role To Add"
    },
    model: {
      value: _vm.addOption,
      callback: function callback($$v) {
        _vm.addOption = $$v;
      },
      expression: "addOption"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }